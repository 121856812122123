<template>
  <div class="language-container">
    <v-select :clearable="false"
              :searchable="false"
              v-model="$root.$i18n.locale"
              :options="languages">
    </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  data() {
    return {
      languages: ['en', 'ru']
    }
  },
  components: {
    vSelect
  },
}
</script>

<style lang="scss" scoped>
.language-container {
  margin-right: 20px;

  ::v-deep.v-select {
    .vs__dropdown-toggle {
      border: 1px solid white;
    }

    .vs__selected {
      position: relative !important;
      color: white;
      width: 20px;
    }

    .vs__open-indicator {
      path {
        fill: white;
      }
    }
  }
}

</style>