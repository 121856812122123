<template>
  <div class="mobile-nav-bar">
    <div class="mobile-header">
      <img src="../../assets/images/fortchain_full.png" class="image" alt="">
      <div class="mobile-right-cont">
        <language-exchanger/>
        <div :class="{open:showMenu}" @click="showMenu=!showMenu" class="icon nav-icon-5">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="line"></div>
    </div>
    <transition name="fade">
      <nav-bar v-if="showMenu" class="mobile-nav-bar-popup"/>
    </transition>
  </div>
</template>

<script>
import NavBar from "./NavBar";
import LanguageExchanger from "../LanguageExchanger";

export default {
  data() {
    return {
      showMenu: false
    }
  },
  components: {
    NavBar,
    LanguageExchanger
  },
  watch: {
    $route() {
      this.showMenu = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-nav-bar {
  display: none;
  @media only screen and (max-width: 1023px) {
    display: flex;
  }

  .mobile-header {
    @include space-between;
    position: relative;
    width: 100%;
    padding: 13px 19px;
    z-index: 10;
    background: #171717;

    .image {
      width: 200px;
      @media only screen and (max-width: 767px) {
        width: 150px;
      }

    }

    .line {
      position: absolute;
      width: calc(100% - 38px);
      height: 1px;
      background: white;
      bottom: 0;
    }

    .mobile-right-cont {
      @include flex;
    }

    ::v-deep .language-container {
      .vs__actions {
        display: none;
      }

      .vs__selected {
        padding: 0;
        margin: 0;
        width: 30px;
        @include center-children;
      }

      .vs__search {
        display: none;
      }
    }

    .nav-icon-5 {
      width: 35px;
      height: 30px;
      position: relative;
      cursor: pointer;
      display: inline-block;
    }

    .nav-icon-5 span {
      background-color: #fff;
      position: absolute;
      border-radius: 2px;
      transition: .3s cubic-bezier(.8, .5, .2, 1.4);
      width: 100%;
      height: 3px;
      transition-duration: 500ms
    }

    .nav-icon-5 span:nth-child(1) {
      top: 0;
      left: 0;
    }

    .nav-icon-5 span:nth-child(2) {
      top: 13px;
      left: 0;
      opacity: 1;
    }

    .nav-icon-5 span:nth-child(3) {
      bottom: 0;
      left: 0;
    }

    .nav-icon-5:not(.open):hover span:nth-child(1) {
      transform: rotate(-3deg) scaleY(1.1);
    }

    .nav-icon-5:not(.open):hover span:nth-child(2) {
      transform: rotate(3deg) scaleY(1.1);
    }

    .nav-icon-5:not(.open):hover span:nth-child(3) {
      transform: rotate(-4deg) scaleY(1.1);
    }

    .nav-icon-5.open span:nth-child(1) {
      transform: rotate(45deg);
      top: 13px;
    }

    .nav-icon-5.open span:nth-child(2) {
      opacity: 0;
    }

    .nav-icon-5.open span:nth-child(3) {
      transform: rotate(-45deg);
      top: 13px;
    }
  }

  .mobile-nav-bar-popup {
    display: block;
    z-index: 9;
    position: fixed;
    width: 100%;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s ease;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
}
</style>