<template>
  <div :class="{'receive-view':receivePage}" class="dashboard-content">
    <div class="dashboard-header">
      <div class="left">
      <span class="header-title">{{ activeHeaderTexts.name }}</span>
        <span class="description">{{ activeHeaderTexts.title }}</span>
      </div>
      <div class="right">
        <language-exchanger/>
        <template v-if="isVerified">
<!--          <router-link to="/deposit" class="button">-->
<!--            <img src="../../assets/images/titlt_arrow_down.png" alt="">-->
<!--            <span class="title">{{ $tc('deposit') }}</span>-->
<!--          </router-link>-->
          <router-link to="/withdraw" class="button color">
            <img src="../../assets/images/titlt_arrow_up.png" alt="">
            <span class="title">{{ $tc('withdraw') }}</span>
          </router-link>
        </template>
        <Button v-else @click.native="goVerify"
                :showModal="false"
                title="verify"/>
      </div>
    </div>
    <mobile-nav-bar/>
    <div class="dashboard-router-content">
      <transition mode="out-in" name="rout-fade">
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";
import MobileNavBar from '../dashboard/MobileNavBar'
import {mapGetters} from "vuex";
import LanguageExchanger from "../LanguageExchanger";

export default {
  components: {
    Button,
    MobileNavBar,
    LanguageExchanger
  },
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
    }),
    pageData() {
      return {
        dashboard: {
          name: this.$tc('dashboard'),
          title: 'In this section you can manage your wallet'
        },
        deposit: {
          name: this.$tc('deposit'),
          title: 'in this section you can deposit funds to your account'
        },
        withdraw: {
          name: this.$tc('withdraw'),
          title: 'in this section you can withdraw funds from your account'
        },
        staking: {
          name: this.$tc('staking'),
          title: 'in this section you can manage your staking'
        },
        settings: {
          name: this.$tc("settings"),
          title: 'in this section you can manage your settings'
        },
        hedgeFund: {
          name: this.$tc('hedgeFund'),
          title: 'in this section you can manage your funds'
        },
        cryptoHedgeFund: {
          name: this.$tc('cryptoHedgeFund'),
          title: 'in this section you can manage your funds'
        },
      }
    },
    isVerified() {
      return this.user.signInUserSession.accessToken.payload['cognito:groups']?.includes('KYC')
    },
    receivePage() {
      return this.$route?.name === 'receive'
    },
    activeRoute() {
      return this.$route?.name
    },
    activeHeaderTexts() {
      return this.pageData[this.activeRoute || 'dashboard']
    },
  },
  methods: {
    goVerify() {
      this.$router.push('/verification')
    },
    changeLanguage() {
      console.log(this.$root.$i18n)
    }
  },
}
</script>

<style lang="scss" scoped>
.dashboard-content {
  position: absolute;
  height: 100vh;
  overflow: hidden;
  right: 0;
  background: #1e1e1e;
  min-height: 100vh;
  width: calc(100% - 400px);
  padding: 32px 0;
  @media only screen and (max-width: 1650px) and (min-width: 1366px) {
    width: calc(100% - 350px);
    padding: 20px 0;
  }
  @media only screen and (max-width: 1365px) and (min-width: 1024px) {
    width: calc(100% - 280px);
    padding: 20px 0;
  }
  @media only screen and (max-width: 1023px) {
    width: 100%;
    padding: 0;
    background: #171717;
  }


  .dashboard-header {
    padding: 0 27px;
    @include space-between;
    @media only screen and (max-width: 1440px) and (min-width: 1024px) {
      padding: 0 19px;
    }
    @media only screen and (max-width: 1023px) {
      display: none;
    }

    .header-title {
      font-style: normal;
      font-weight: 300;
      font-size: 40px;
      color: #FFFFFF;
      @media only screen and (max-width: 1440px) and (min-width: 1366px) {
        font-size: 35px;
      }
      @media only screen and (max-width: 1365px) and (min-width: 1024px) {
        font-size: 30px;
      }
    }

    .description {
      font-weight: 300;
      font-size: 20px;
      line-height: 24px;
      color: #AFA9A9;
      @media only screen and (max-width: 1440px) and (min-width: 1366px) {
        font-size: 18px;
      }
      @media only screen and (max-width: 1365px) and (min-width: 1024px) {
        font-size: 16px;
      }
    }

    .right {
      @include flex;

      .trade-title {
        color: $base-color;
        font-weight: 500;
        font-size: 24px;
        margin-right: 20px;
      }

      .button {
        @include flex;
        height: 60px;
        padding-left: 20px;
        border: 1px solid white;
        border-radius: 58px;
        font-weight: 500;
        font-size: 20px;
        color: #FFFFFF;
        min-width: 171px;
        cursor: pointer;
        text-decoration: none;

        @media only screen and (max-width: 1800px) and (min-width: 1366px) {
          font-size: 18px;
        }
        @media only screen and (max-width: 1365px) and (min-width: 1024px) {
          font-size: 16px;
          width: 140px;
          height: 50px;
        }

        &:last-child {
          margin-left: 21px;
        }

        img {
          margin-right: 10px;
        }

        &.color {
          background: $base-color;
          color: #1E1E1E;
          border: 1px solid #42E8E0;
        }

        .title {
          padding-right: 20px;
        }
      }

      .button-container {
        margin-top: 0;
        margin-left: 21px;
      }
    }
  }

  .dashboard-router-content {
    margin-top: 32px;
    overflow-y: overlay;
    height: calc(100vh - 135px);
    position: relative;
    @media only screen and (max-width: 1023px) {
      margin-top: 0;
      padding-top: 20px;
      height: calc(100vh - 62px);
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>