<template>
  <div class="nav-bar">
    <div class="up">
      <router-link to="/" class="logo-container">
        <div class="img-container">
          <img src="@/assets/images/fortchain_new.png" class="image" alt="">
        </div>
        <span class="title">Fortchain</span>
      </router-link>
      <div class="user-info-inner">
        <div class="user-info">
          <div class="image-container">
            <img src="../../assets/images/user.png" class="image" alt="">
          </div>
          <div class="user-content">
            <span :title="userInfo.email" v-line-clamp="1" class="user-title"> {{ user.email }}</span>
            <span class="user-title">Your ID {{ user.altId }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="list">
      <router-link v-for="item in menu"
                   :class="{active:routerPath===item.name}"
                   :key="item.name"
                   :to="item.link||'/'"
                   class="list-item">
        <div class="list-content">
          <img :src="item.url" alt="">
          <span class="title">{{ item.title }}</span>
        </div>
        <div class="background"></div>
        <div class="line"></div>
      </router-link>
    </div>
    <div class="down">
      <div @click="signOut" class="log-out">
        <div class="img-container">
          <img src="../../assets/images/log_out.png" alt="">
        </div>
        <span class="title">{{ $tc('logOut') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {Auth} from 'aws-amplify';

export default {
  data() {
    return {
      user: {}
    }
  },
  computed: {
    userInfo() {
      return this.user?.attributes || {}
    },
    routerPath() {
      return this.$route.name
    },
    menu() {
      return [
        {
          title: this.$tc('dashboard'),
          name: '',
          link: '',
          url: require('../../assets/images/dashboard.png'),
        },
        // {
        //   title: this.$tc('deposit'),
        //   name: 'deposit',
        //   link: 'deposit',
        //   url: require('../../assets/images/receive.png'),
        // },
        {
          title: this.$tc('withdraw'),
          name: 'withdraw',
          link: 'withdraw',
          url: require('../../assets/images/withdraw.png'),
        },
        {
          title: this.$tc('staking'),
          name: 'staking',
          link: 'staking',
          url: require('../../assets/images/staking.png'),
        },
        {
          title: this.$tc('hedgeFund'),
          name: 'hedgeFund',
          link: 'hedge-fund',
          url: require('../../assets/images/tvl.png'),
        },
        {
          title: this.$tc('cryptoHedgeFund'),
          name: 'cryptoHedgeFund',
          link: 'crypto-hedge-fund',
          url: require('../../assets/images/tvl.png'),
        },
        // {
        //   title: this.$tc('settings'),
        //   name: 'settings',
        //   link: 'settings',
        //   url: require('../../assets/images/setting.png'),
        // },
      ]
    },
  },
  methods: {
    signOut() {
      try {
        Auth.signOut().then(() => {
          this.$router.push({path: 'sign-in'})
        });
      } catch (error) {
        console.log('error signing out: ', error);
      }
    },
    getUser() {
      this.$axios.get('api/v1/user').then(({data}) => {
        this.user = data
      })
    }
  },
  created() {
    this.getUser()
  }
}
</script>

<style lang="scss" scoped>
.nav-bar {
  position: fixed;
  height: 100vh;
  width: 400px;
  background: #171717;
  padding-top: 32px;
  overflow-y: overlay;
  overflow-x: hidden;
  padding-bottom: 20px;

  @media only screen and (max-width: 1650px) and (min-width: 1366px) {
    width: 350px;
    padding-top: 20px;
  }
  @media only screen and (max-width: 1365px) and (min-width: 1024px) {
    width: 280px;
    padding-top: 20px;
  }
  @media only screen and (max-width: 1023px) and (min-width: 768px) {
    display: none;
    padding-top: 110px;
  }
  @media only screen and (max-width: 767px) {
    display: none;
    padding-top: 90px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .up {
    padding-left: 29px;
    width: fit-content;
    max-width: 90%;
    @media only screen and (max-width: 1440px) and (min-width: 1024px) {
      padding-left: 20px;
    }
    @media only screen and (max-width: 1023px) {
      padding-left: unset;
      position: relative;
      margin: 0 auto;
      width: 87%;
    }

    .logo-container {
      @include flex;
      text-decoration: none;

      @media only screen and (max-width: 1023px) {
        display: none;
      }

      .img-container {
        @include center-children;
        border: 3px solid #1280A0;
        border-radius: 50%;
        height: 70px;
        width: 70px;

        img {
          width: 25px;
        }
      }

      .title {
        margin-left: 20px;
        font-weight: 400;
        font-size: 25px;
      }

      padding: 0 13px 0 0;
    }

    .user-info-inner {
      margin-top: 30px;
      padding-top: 18px;
      border-top: 1px solid #363636;
      max-width: 100%;
      @media only screen and (max-width: 1440px) and (min-width: 1366px) {
        margin-top: 20px;
      }
      @media only screen and (max-width: 1440px) and (min-width: 1024px) {
        margin-top: 15px;
      }
      @media only screen and (max-width: 1023px) {
        border-top: unset;
        margin-top: 0;
        padding-top: 0;
      }

      .user-info {
        @include center-children;
        height: 80px;
        background: linear-gradient(180deg, #3957B0 0%, rgba(30, 30, 30, 0) 100%);
        border-radius: 20px;
        padding: 10px 16px 12px;
        width: fit-content;
        @media only screen and (max-width: 1650px) and (min-width: 1441px) {
          margin-top: 20px;
        }
        @media only screen and (max-width: 1440px) and (min-width: 1024px) {
          height: 65px;
        }
        @media only screen and (max-width: 1023px) {
          width: 100%;
          justify-content: flex-start;
        }

        .image-container {
          @include center-children;
          min-width: 58px;
          width: 58px;
          height: 58px;
          background: linear-gradient(225.87deg, #181C30 0%, rgba(19, 120, 227, 0.48) 113.82%);
          border-radius: 20px;
          @media only screen and (max-width: 1440px) and (min-width: 1024px) {
            width: 42px;
            min-width: 42px;
            height: 42px;
            padding: 12px;
            img {
              width: 20px;
            }
          }

        }

        .user-content {
          margin-left: 10px;

          .user-title {
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            color: #FFFFFF;
            @media only screen and (max-width: 1440px) and (min-width: 1024px) {
              font-size: 15px;
            }

            &:first-child {
              margin-bottom: 8px;
              @media only screen and (max-width: 1440px) and (min-width: 1024px) {
                margin-bottom: 5px;
              }
            }
          }
        }
      }
    }
  }

  .title {
    font-weight: 300;
    font-size: 20px;
    color: #FFFFFF;
    @media only screen and (max-width: 1440px) and (min-width: 1366px) {
      font-size: 18px;
    }
    @media only screen and (max-width: 1365px) and (min-width: 1024px) {
      font-size: 16px;
    }
  }

  .list {
    margin-top: 65px;
    width: 100%;
    @media only screen and (max-width: 1650px) and (min-width: 1441px) {
      margin-top: 45px;
    }
    @media only screen and (max-width: 1440px) and (min-width: 1366px) {
      margin-top: 35px;
    }
    @media only screen and (max-width: 1440px) and (min-width: 1024px) {
      margin-top: 25px;
    }
    @media only screen and (max-width: 1023px) {
      margin-top: 30px;
    }

    .list-item {
      @include flex;
      width: 100%;
      height: 61px;
      padding-left: 27px;
      position: relative;
      cursor: pointer;
      text-decoration: none;
      @media only screen and (max-width: 1440px) and (min-width: 1366px) {
        height: 50px;
      }
      @media only screen and (max-width: 1440px) and (min-width: 1024px) {
        padding-left: 20px;
      }
      @media only screen and (max-width: 1365px) {
        height: 45px;
      }
      @media only screen and (max-width: 1023px) {
        padding-left: 19px;
      }

      .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, #1B69EB -9.88%, rgba(67, 142, 255, 0) 92.22%);
        transition: all .4s ease-out;
        pointer-events: none;
        opacity: 0;
      }

      .list-content {
        @include flex;
        z-index: 1;
        position: relative;

        .title {
          margin-left: 20px;
        }

        img {
          width: 20px;
        }
      }

      .line {
        position: absolute;
        top: 0;
        left: 27px;
        width: 270px;
        height: 1px;
        background: #363636;
        opacity: 1;
        transition: all .4s ease-out;
        @media only screen and (max-width: 1023px) {
          left: 19px;
          width: calc(100% - 38px);
        }
      }

      &.active {
        .background {
          opacity: 0.41;
        }

        .line {
          opacity: 0;
        }
      }

      &.active + .list-item {

        .line {
          opacity: 0;
        }
      }
    }
  }

  .down {
    padding-left: 29px;
    @media only screen and (max-width: 1440px) and (min-width: 1024px) {
      padding-left: 20px;
    }
    @media only screen and (max-width: 1023px) {
      padding-left: 19px;
    }

    .setting {
      @include flex;
      width: 270px;
      height: 70px;
      background: #1E1E1E;
      border-radius: 77px;
      padding: 7px 9px;
      margin-top: 35px;
      cursor: pointer;
      text-decoration: none;

      @media only screen and (max-width: 1650px) and (min-width: 1441px) {
        margin-top: 30px;
      }
      @media only screen and (max-width: 1440px) and (min-width: 1366px) {
        width: 240px;
        height: 55px;
        margin-top: 25px;
      }
      @media only screen and (max-width: 1365px) and (min-width: 1024px) {
        width: 180px;
        height: 50px;
        margin-top: 25px;
      }
      @media only screen and (max-width: 1023px) {
        margin-top: 25px;;
      }

      .img-container {
        @include center-children;
        border-radius: 50%;
        width: 56px;
        height: 56px;
        background: #171717;
        @media only screen and (max-width: 1440px) and (min-width: 1366px) {
          width: 46px;
          height: 46px;
          img {
            width: 20px;
          }
        }
        @media only screen and (max-width: 1365px) and (min-width: 1024px) {
          width: 40px;
          height: 40px;
          img {
            width: 18px;
          }
        }
      }

      .title {
        margin-left: 45px;
        @media only screen and (max-width: 1440px) and (min-width: 1366px) {
          margin-left: 30px;
        }
        @media only screen and (max-width: 1365px) and (min-width: 1024px) {
          margin-left: 25px;
        }
      }
    }

    .log-out {
      @include flex;
      margin-top: 35px;
      cursor: pointer;
      @media only screen and (max-width: 1650px) and (min-width: 1441px) {
        margin-top: 30px;
      }
      @media only screen and (max-width: 1440px) {
        margin-top: 25px;
      }

      .img-container {
        @include center-children;
        border-radius: 50%;
        width: 56px;
        height: 56px;
        background: #42E8E0;
        @media only screen and (max-width: 1440px) and (min-width: 1366px) {
          width: 46px;
          height: 46px;
          img {
            width: 20px;
          }
        }
        @media only screen and (max-width: 1365px) and (min-width: 1024px) {
          width: 40px;
          height: 40px;
          img {
            width: 18px;
          }
        }
      }

      .title {
        margin-left: 29px;
        @media only screen and (max-width: 1440px) and (min-width: 1024px) {
          margin-left: 20px;
        }
      }
    }
  }

}
</style>