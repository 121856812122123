<template>
  <div class="dashboard">
    <nav-bar/>
    <Content/>
    <Index/>
  </div>
</template>

<script>
import NavBar from '@/components/dashboard/NavBar'
import Content from '@/components/dashboard/Content'
import Index from '@/components/modals/Index'
import {mapActions} from "vuex";

export default {
  components: {
    NavBar,
    Content,
    Index
  },
  methods: {
    ...mapActions({
    })
  },
}
</script>

<style lang="scss" scoped>
.dashboard {
  display: flex;
}
</style>